html {
  font-size: 14px;
}

.MuiTableCell-root {
  border-right-style: solid;
  border-right-width: thin;
  border-right-color: lightgrey;

  border-top-style: solid;
  border-top-width: thin;
  border-top-color: lightgrey;
}

.MuiTableCell-head {
  font-weight: bold !important;
}

tbody > .MuiTableRow-root:hover {
  background-color: rgb(196, 196, 196) !important;
}

.MuiDialogTitle-root {
  box-shadow: 0px 1px 3px grey;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

* {
  box-sizing: border-box;
}

#ccp-wrapper {
  position: fixed;
  bottom: 3px;
  right: 3px;
}

#ccp-div-frame {
  width: fit-content;
  height: 400px;
  transition: 0.3s ease-out;
}

.chatbox-top {
  position: relative;
  display: flex;
  padding: 1px 0;
  border-radius: 10px 10px 0 0;
  background: black;
}

.ccp-icon {
  font-size: 20px !important;
}


